import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";

const GlobalStyles = createGlobalStyle`
    ${normalize};
    
    *, *::before, *::after {
      box-sizing: border-box;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
    
    html {
      font-size: 10px;
    }

    html.has-scroll-smooth {
      overflow: hidden;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }


    body {
      font-size: 1.6rem;
      background: ${({ theme }) => theme.background};
      color: ${({ theme }) => theme.foreground};
      font-family: 'Roboto', sans-serif;
    }
    
    a {
      text-decoration: none;
    }
    
    #scroll-wrapper {
      width: 100%;
      min-height: 100vh;
    }
    
    .c-scrollbar {
      z-index: 999999;
    }
    
    .c-scrollbar_thumb {
      background: ${({ theme }) => theme.washLight}!important;
    }
`;

export default GlobalStyles;
