import React from "react";
import styled from "styled-components";
import Logo from "@components/atoms/Logo/Logo";
import { NAVIGATION_ITEMS } from "@config";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { graphql, useStaticQuery } from "gatsby";
import facebookIcon from "@iconify/icons-bx/bxl-facebook-square";
import instagramIcon from "@iconify/icons-bx/bxl-instagram-alt";
import { Icon } from "@iconify/react";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";

const StyledWrapper = styled.footer`
  padding: 80px 0 60px;
  background: ${({ theme }) => theme.backgroundSecondary};
`;

const StyledInnerWrapper = styled.div`
  max-width: 1700px;
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const StyledFirstRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 35px;
  border-bottom: 1px solid ${({ theme }) => theme.washLighter};

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-bottom: 40px;
  }
`;

const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    margin-top: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 30px;
    width: calc(100% - 30px);
  }
`;

const StyledItem = styled.li`
  margin-right: 30px;
  font-size: 2rem;

  :last-of-type {
    margin-right: 0;
  }

  a {
    color: ${({ theme }) => theme.foreground};
    text-decoration: none;

    ${withLineHoverEffect()}
  }

  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const StyledSecondRow = styled.div`
  padding-top: 25px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
  }
`;

const StyledSocialMediaWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSocialMedia = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.foreground};
  padding-bottom: 5px;

  :first-of-type {
    margin-right: 30px;
  }

  svg {
    font-size: 2rem;
    margin-right: 6px;
  }

  span {
    line-height: 1px;
  }

  ${withLineHoverEffect()}
`;

const StyledAuthor = styled.span`
  a {
    color: ${({ theme }) => theme.foreground};
    font-weight: 700;

    :hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 1024px) {
    margin-top: 30px;
  }
`;

const StyledCopyright = styled.div`
  @media (max-width: 1024px) {
    order: 3;
    margin-top: 30px;
  }
`;

const Footer = () => {
  const {
    directus: { settings },
  } = useStaticQuery<Query>(query);

  return (
    <StyledWrapper data-scroll-section>
      <StyledInnerWrapper>
        <StyledFirstRow>
          <Logo />

          <StyledList>
            {NAVIGATION_ITEMS.map(({ name, href }) => (
              <StyledItem key={href}>
                <ScrollTo href={href}>{name}</ScrollTo>
              </StyledItem>
            ))}
          </StyledList>
        </StyledFirstRow>

        <StyledSecondRow>
          <StyledSocialMediaWrapper>
            <StyledSocialMedia href={settings.facebook}>
              <Icon icon={facebookIcon} />
              <span>Facebook</span>
            </StyledSocialMedia>
            <StyledSocialMedia href={settings.instagram}>
              <Icon icon={instagramIcon} />
              <span>Instagram</span>
            </StyledSocialMedia>
          </StyledSocialMediaWrapper>

          <StyledCopyright>2022 &copy; Marys Deweloper</StyledCopyright>

          <StyledAuthor>
            Stworzone z ❤️ przez{" "}
            <a href="https://rend.pro/offer/developer-website" target="_blank" rel="noopener">
              RendPro.com
            </a>
          </StyledAuthor>
        </StyledSecondRow>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      settings {
        facebook
        instagram
      }
    }
  }
`;

interface Query {
  directus: {
    settings: {
      facebook: string;
      instagram: string;
    };
  };
}

export default Footer;
