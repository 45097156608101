import React, { FC } from "react";
import StarsSvg from "@assets/svg/stars.svg";
import styled from "styled-components";

const StyledStars = styled(StarsSvg)`
  display: none;

  @media (min-width: 1025px) {
    display: block;
  }
`;

const Stars: FC<React.SVGProps<SVGElement>> = (props) => (
  <StyledStars {...props} />
);

export default Stars;
