import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/index";
import Headline from "@components/atoms/Headline/Headline";
import Input from "@components/atoms/Input/Input";
import Checkbox from "@components/atoms/Checkbox/Checkbox";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "@components/atoms/Button/Button";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { easeOutCirc } from "@theme/easings";
import { transformPhoneNumber } from "@utils/transformPhoneNumber";

const StyledWrapper = styled.div`
  width: 100%;
  padding: 80px 0 0;
  background: ${({ theme }) => theme.backgroundSecondary};
  position: relative;
`;

const StyledContainer = styled.div`
  max-width: 1920px;
  width: 100%;
  margin: auto;
`;

const StyledInnerWrapper = styled.section`
  width: 100%;
  display: flex;
  background: ${({ theme }) => theme.background};
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-top: 80px;
  }
`;

const StyledLeftWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;

  @media (max-width: 1024px) {
    width: 90%;
    margin: auto;
    padding: 0;
  }
`;

const StyledLeftInnerWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  max-width: 550px;

  @media (max-width: 1440px) {
    max-width: 450px;
  }
`;

const StyledImageWrapper = styled.div`
  max-width: 1060px;
  height: 1160px;
  width: 55vw;
  overflow: hidden;

  @media (max-width: 1024px) {
    width: 100%;
    height: unset;
    max-height: 600px;
    margin-top: 80px;
  }
`;

const StyledImageInnerWrapper = styled(motion.div)`
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    height: unset;
  }
`;

const StyledImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const StyledContactPropertiesWrapper = styled.div`
  margin-top: 35px;
  display: flex;

  @media (max-width: 1024px) {
    margin-top: 60px;
    flex-wrap: wrap;
  }
`;

const StyledContactPropety = styled.a`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 50px;
  color: ${({ theme }) => theme.foreground};
  text-decoration: none;

  :last-child {
    margin-right: 0;
  }

  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const StyledContactPropertyName = styled.h3`
  font-size: 2rem;
  font-weight: 700;
  margin: 0 0 10px;
`;

const StyledContactPropertyValue = styled.p`
  margin: 0;
  line-height: 1.5;
  display: flex;
  flex-direction: column;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledForm = styled.form`
  margin-top: 50px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledInput = styled(Input)`
  width: 100%;
  margin-top: 25px;

  :first-of-type {
    margin-top: 0;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 25px;
`;

const StyledTextarea = styled(StyledInput).attrs(() => ({
  forwardedAs: "textarea",
}))`
  height: 150px;
  line-height: 1.5;

  textarea {
    resize: none;
  }

  div {
    top: 32px;
  }
`;

const StyledReCaptchaWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 25px 0;

  div {
    margin: 0 !important;
  }

  span {
    font-size: 1.2rem;
    color: #ff6a6a;
    margin-top: 10px;
    display: block;
  }

  iframe {
    transform-origin: left top;
  }

  @media (max-width: 370px) {
    margin: 30px 0 10px;
    iframe {
      transform: scale(0.8);
    }
  }
`;

const AfterSendMessage = styled.div<{ $isSuccess: boolean }>`
  color: ${({ $isSuccess }) => ($isSuccess ? "#88c05a" : "#ff6a6a")};
  text-align: center;
  font-weight: 800;
  font-size: 1.8rem;
  position: relative;
  margin-top: 40px;

  @media (max-width: 1024px) {
    margin-top: 80px;
  }

  @media (max-width: 650px) {
    font-size: 1.6rem;
  }
`;

const Contact = () => {
  const {
    directus: { settings },
  } = useStaticQuery<Query>(query);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [captchaKey, setCaptchaKey] = useState<string>();
  const [wasSendTry, setWasSendTry] = useState<boolean>(false);
  const [successfulSend, setSuccessfulSend] = useState<boolean>(false);
  const [errorSend, setErrorSend] = useState<boolean>(false);
  const { ref, inView } = useInView();

  const onSubmit: SubmitHandler<any> = (data) => {
    console.log(data);
    if (!captchaKey) {
      setWasSendTry(true);
      return;
    }

    axios
      .post(`https://formsubmit.co/ajax/biuro@marysdeweloper.pl`, {
        Imię: data.name,
        "E-mail": data.email,
        "Numer telefonu": data.phoneNumber || "Nie podano",
        "Wiadomość ": data.message,
      })
      .then((res) => {
        res.status === 200 ? setSuccessfulSend(true) : setErrorSend(true);
      })
      .catch(() => {
        setErrorSend(true);
      });
  };

  useEffect(() => {
    if (
      (errors.message ||
        errors.name ||
        errors.email ||
        errors.consent ||
        errors.phoneNumber) &&
      !captchaKey
    ) {
      setWasSendTry(true);
    }
  }, [errors.message, errors.name, errors.email, errors.consent]);

  return (
    <StyledWrapper id="contact" data-scroll-section>
      <StyledContainer>
        <StyledInnerWrapper>
          <StyledLeftWrapper>
            <StyledLeftInnerWrapper>
              <Headline>Skontaktuj się z nami</Headline>
              <StyledContactPropertiesWrapper>
                <StyledContactPropety href={`mailto:${settings.e_mail}`}>
                  <StyledContactPropertyName>E-mail:</StyledContactPropertyName>
                  <StyledContactPropertyValue>
                    {settings.e_mail}
                  </StyledContactPropertyValue>
                </StyledContactPropety>
                <StyledContactPropety as="div">
                  <StyledContactPropertyName>
                    Telefon:
                  </StyledContactPropertyName>
                  <StyledContactPropertyValue>
                    {settings.phone_number.includes(",") ? (
                      settings.phone_number.split(",").map((num) => (
                        <a href={transformPhoneNumber(num)} key={num}>
                          {num}
                        </a>
                      ))
                    ) : (
                      <a href={transformPhoneNumber(settings.phone_number)}>
                        {settings.phone_number}
                      </a>
                    )}
                  </StyledContactPropertyValue>
                </StyledContactPropety>
                <StyledContactPropety
                  href={settings.address_link}
                  target="_blank"
                  rel="noopener"
                >
                  <StyledContactPropertyName>Adres:</StyledContactPropertyName>
                  <StyledContactPropertyValue>
                    {settings.address}
                  </StyledContactPropertyValue>
                </StyledContactPropety>
              </StyledContactPropertiesWrapper>

              {!successfulSend && !errorSend ? (
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                  <StyledInput
                    placeholder="Imię i nazwisko"
                    required
                    error={
                      errors.name ? "Imię i nazwisko jest wymagane." : undefined
                    }
                    {...register("name", { required: true })}
                  />
                  <StyledInput
                    placeholder="Adres e-mail"
                    required
                    error={
                      errors.email?.type === "required"
                        ? "E-mail jest wymagany."
                        : errors.email?.type === "pattern"
                        ? "E-mail jest nieprawidłowy."
                        : undefined
                    }
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })}
                  />
                  <StyledInput
                    placeholder="Numer telefonu"
                    {...register("phoneNumber")}
                  />
                  <StyledTextarea
                    placeholder="Wiadomość"
                    required
                    error={errors.message ? "Napisz wiadomość." : undefined}
                    {...register("message", { required: true })}
                  />
                  <StyledCheckbox
                    label="Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania"
                    error={errors.consent ? "Zgoda jest wymagana." : undefined}
                    {...register("consent", { required: true })}
                  />

                  <StyledReCaptchaWrapper>
                    <ReCAPTCHA
                      sitekey={process.env.GATSBY_GOOGLE_RECAPTCHA}
                      hl="pl"
                      onChange={(key) => {
                        setCaptchaKey(key);
                        setWasSendTry(false);
                      }}
                      theme="dark"
                    />
                    {!captchaKey && wasSendTry && (
                      <span>Potwierdź, że nie jesteś robotem</span>
                    )}
                  </StyledReCaptchaWrapper>

                  <Button type="submit">Wyślij</Button>
                </StyledForm>
              ) : (
                <AfterSendMessage $isSuccess={successfulSend}>
                  {successfulSend
                    ? "Twoja wiadomość została wysłana pomyślnie"
                    : "Przepraszamy, coś poszło nie tak... Spróbuj ponownie później"}
                </AfterSendMessage>
              )}
            </StyledLeftInnerWrapper>
          </StyledLeftWrapper>

          <StyledImageWrapper>
            <StyledImageInnerWrapper
              ref={ref}
              initial={{ scale: 1.2 }}
              animate={inView && { scale: 1 }}
              transition={{ duration: 2, ease: easeOutCirc }}
            >
              <StyledImage
                image={getImage(
                  settings.contact_image.imageFile.childImageSharp
                )}
                alt=""
              />
            </StyledImageInnerWrapper>
          </StyledImageWrapper>
        </StyledInnerWrapper>
      </StyledContainer>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      settings {
        address
        address_link
        e_mail
        phone_number
        contact_image {
          id
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 80, placeholder: DOMINANT_COLOR)
            }
          }
        }
      }
    }
  }
`;

interface Query {
  directus: {
    settings: {
      address: string;
      address_link: string;
      e_mail: string;
      phone_number: string;
      contact_image: {
        id: string;
        imageFile: ChildImageSharp;
      };
    };
  };
}

export default Contact;
