import { PropertyTypeName } from "@interfaces/index";

export const getPropertyName = (type: PropertyTypeName) => {
  switch (type) {
    case "location":
      return "Adres";
    case "delivery_date":
      return "Termin oddania";
    case "area":
      return "Powierzchnia";
    case "parcel_area":
      return "Powierzchnia działki";
    case "garage":
      return "Garaż";
    case "rooms_count":
      return "Ilość pokoi";
  }
};
