import React, { FC, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Footer from "@components/molecules/Footer/Footer";
import { ThemeProvider } from "styled-components";
import { theme } from "@theme/theme";
import { MainContext } from "@context/index";
import GlobalStyles from "@theme/GlobalStyles";
import Navigation from "@components/molecules/Navigation/Navigation";
import Loader from "@components/atoms/Loader/Loader";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { useLocation } from "react-use";
import "locomotive-scroll/dist/locomotive-scroll.css";

const BasicTemplate: FC<Props> = ({
  title,
  children,
  renderHero: Hero,
  ogImage,
  description,
  subpageTitle,
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isLoaderExited, setLoaderExited] = useState<boolean>(!isLoading);
  const containerRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    const getVh = () => {
      const vh = typeof window !== "undefined" && window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    getVh();

    window.addEventListener("resize", getVh);

    setLoading(true);
    setLoaderExited(false);
    const timeout = setTimeout(() => setLoading(false), 1000);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", getVh);
    };
  }, []);

  const readyTitle = `${subpageTitle ? subpageTitle + " | " : ""}${title}`;

  return (
    <>
      <Helmet>
        <title>{readyTitle}</title>
        <html lang="pl" />

        <meta name="description" content={description} />

        <meta property="og:title" content={readyTitle} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
      </Helmet>

      <ThemeProvider theme={theme}>
        <MainContext.Provider
          value={{
            isLoading,
            isLoaderExited,
          }}
        >
          <GlobalStyles />

          <LocomotiveScrollProvider
            options={{
              smooth: true,
              lerp: 0.08,
            }}
            containerRef={containerRef}
            onLocationChange={(scroll: any) =>
              scroll.scrollTo(0, { duration: 0, disableLerp: true })
            }
            location={pathname}
            watch={[]}
          >
            <div ref={containerRef} data-scroll-container="">
              <header>
                <Navigation />
                {Hero && <Hero />}
              </header>

              <main>{children}</main>
              <Footer />
            </div>
          </LocomotiveScrollProvider>

          <Loader
            isLoading={isLoading}
            setLoaderExited={setLoaderExited}
            isLoaderExited={isLoaderExited}
          />
        </MainContext.Provider>
      </ThemeProvider>
    </>
  );
};

interface Props {
  title?: string;
  subpageTitle?: string;
  renderHero?: FC;
  description: string;
  ogImage: string;
}

export default BasicTemplate;
