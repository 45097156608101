import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { AnimatePresence, motion } from "framer-motion";
import Logo from "@assets/svg/logo_without_text.svg";
import gsap from "gsap";
import { easeInOutCirc } from "@theme/easings";

const StyledWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999999999999;
  background: ${({ theme }) => theme.background};
`;

const StyledLogoWrapper = styled(motion.div)`
  background: ${({ theme }) => theme.backgroundSecondary};
  border-radius: 40px;
  padding: 60px;
  box-shadow: 0 0 40px rgba(255, 255, 255 0.2);

  @media (max-width: 1024px) {
    padding: 50px;
  }
`;

const StyledLogo = styled(Logo)`
  width: 80px;
  height: auto;

  path {
    transform-origin: center center;
    opacity: 0;
  }

  @media (max-width: 720px) {
    width: 60px;
  }
`;

const Loader: FC<Props> = ({ isLoading, isLoaderExited, setLoaderExited }) => {
  const logoWrapper = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!isLoaderExited) {
      const paths = logoWrapper.current.querySelectorAll("path");

      gsap.to(paths, {
        opacity: 0,
        duration: 0,
      });
      gsap.to(paths, {
        opacity: 1,
        stagger: {
          each: 0.1,
        },
        delay: 0.4,
        duration: 0.5,
        repeat: -1,
        ease: "circ.out",
        yoyo: true,
        yoyoEase: true,
      });
    }
  }, [isLoaderExited]);

  return (
    <AnimatePresence
      onExitComplete={() => setLoaderExited(true)}
      initial={false}
    >
      {isLoading && (
        <StyledWrapper
          exit={{ y: "100%" }}
          transition={{ duration: 1.5, ease: easeInOutCirc }}
        >
          <StyledLogoWrapper
            ref={logoWrapper}
            exit={{ scale: 0.6, opacity: 0 }}
            transition={{ duration: 0.9, ease: [0.85, 0, 0.15, 1] }}
          >
            <StyledLogo />
          </StyledLogoWrapper>
        </StyledWrapper>
      )}
    </AnimatePresence>
  );
};

interface Props {
  isLoading: boolean;
  isLoaderExited: boolean;
  setLoaderExited: React.Dispatch<React.SetStateAction<boolean>>;
}

export default Loader;
