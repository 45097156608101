import { IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";

export enum Availability {
  Available = "Dostępne",
  Unavailable = "Niedostępne",
  Reserved = "Zarezerwowane",
}

export interface ChildImageSharp {
  childImageSharp: IGatsbyImageData;
}

export type PropertyTypeName =
  | "location"
  | "delivery_date"
  | "area"
  | "parcel_area"
  | "rooms_count"
  | "garage";

export interface PropertyType {
  type: PropertyTypeName;
  content: string;
}

export type DirectusGallery = {
  directus_files_id: {
    id: string;
    imageFile: {
      fluid: { gatsbyImageData: ImageDataLike };
      fixed: { gatsbyImageData: ImageDataLike };
    };
  };
}[];
