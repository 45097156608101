import React, { FC } from "react";
import styled from "styled-components";
import Decore from "@assets/svg/decore.svg";

const StyledWrapper = styled.div`
  position: relative;
  width: fit-content;
  padding-bottom: 16px;
  z-index: 1;

  h2 {
    font-size: 4.8rem;
    margin: 0;
  }

  @media (max-width: 1024px) {
    margin-left: auto;
    margin-right: auto;

    h2 {
      font-size: 3.8rem;
      text-align: center;
    }
  }
`;

const StyledDecore = styled(Decore)`
  position: absolute;
  left: -5px;
  bottom: 0;
  width: 100%;
  height: 16px;
  z-index: -1;
`;

const Headline: FC<React.HTMLProps<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <StyledWrapper {...(props as any)}>
    <h2>{children}</h2>
    <StyledDecore />
  </StyledWrapper>
);

export default Headline;
