import { PropertyTypeName } from "@interfaces/index";
import {
  addressIcon,
  calendarIcon,
  floorIcon,
  garageIcon,
  roomIcon,
} from "@assets/icons";

export const getIconFromPropertyType = (type: PropertyTypeName) => {
  switch (type) {
    case "location":
      return addressIcon;
    case "delivery_date":
      return calendarIcon;
    case "area":
      return floorIcon;
    case "parcel_area":
      return floorIcon;
    case "garage":
      return garageIcon;
    case "rooms_count":
      return roomIcon;
  }
};
