import React, { FC, MouseEventHandler } from "react";
import styled, { css } from "styled-components";
import { Icon } from "@iconify/react";
import { useButtonEffects, rippleAnimation } from "@rendpro/core-ui";
import { cssEaseOutExpo } from "@theme/easings";

const StyledWrapper = styled.button<{ $isIcon: boolean }>`
  font-size: 2rem;
  padding: 20px 40px;
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: background 0.5s ${cssEaseOutExpo}, color 0.5s ${cssEaseOutExpo};
  position: relative;
  overflow: hidden;
  user-select: none;
  color: #fff;
  background: transparent;

  :hover,
  :active {
    color: ${({ theme }) => theme.background};
    background: ${({ theme }) => theme.primary};
  }

  :active {
    transform: scale(0.97);
    background: ${({ theme }) => theme.primaryDarker};
  }

  .ripple {
    width: 120px;
    height: 120px;
    position: absolute;
    background: ${({ theme }) => theme.background};
    border-radius: 50%;
    transform: scale(0);
    pointer-events: none;
    opacity: 0;
    animation: ${rippleAnimation} 0.6s ease-out;
    transform-origin: center center;

    @media (min-width: 1025px) {
      width: 260px;
      height: 260px;
    }
  }

  ${({ $isIcon }) =>
    $isIcon &&
    css`
      background: ${({ theme }) => theme.primary};
      color: ${({ theme }) => theme.background};
      border: 0;
      padding: 12px 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;

      :hover,
      :active {
        background: ${({ theme }) => theme.primaryDarker};
      }

      :active {
        transform: scale(0.95);
      }
    `};

  @media (max-width: 1024px) {
    font-size: 1.6rem;
    padding: 15px 20px;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
`;

const Button: FC<Props> = ({
  icon,
  children,
  onMouseDown: onMouseDownProp,
  ...props
}) => {
  const { onMouseDown } = useButtonEffects();

  const handleMouseDown: MouseEventHandler<HTMLButtonElement> = (e) => {
    onMouseDown(e);
    onMouseDownProp && onMouseDownProp(e);
  };

  return (
    <StyledWrapper
      onMouseDown={handleMouseDown}
      $isIcon={!!icon}
      {...(props as any)}
    >
      {icon && <StyledIcon icon={icon} />}
      {children}
    </StyledWrapper>
  );
};

interface Props extends React.HTMLProps<HTMLButtonElement> {
  icon?: any;
}

export default Button;
