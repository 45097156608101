import { DefaultTheme } from "styled-components";
import { Availability } from "../interfaces";

export const theme: DefaultTheme = {
  primary: "#EEC83B",
  primaryDarker: "#DDB72A",
  background: "#1B1918",
  backgroundSecondary: "#1E1E1D",
  foreground: "#fff",
  washRegular: "#BBBBBB",
  washLight: "#808080",
  washLighter: "#2c2c2c",
  [Availability.Available]: "#319A3B",
  [Availability.Unavailable]: "#DE2525",
  [Availability.Reserved]: "#E7B52E",
};
