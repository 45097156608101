export const WEBSITE_TITLE = "Marys Deweloper";

export const NAVIGATION_ITEMS: {
  name: string;
  href: string;
  onlyIndex?: false;
}[] = [
  { name: "Wybierz inwestycję", href: "/#investments" },
  { name: "Inne inwestycje", href: "/#other_investments" },
  { name: "O nas", href: "/#about_us", onlyIndex: false },
  { name: "Kontakt", href: "/#contact", onlyIndex: false },
];
