import React, { forwardRef, HTMLProps } from "react";
import { ChangeHandler } from "react-hook-form";
import styled from "styled-components";

const StyledWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledInnerWrapper = styled.label`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.washLight};
  padding: 18px 14px;
  display: flex;
  position: relative;
  height: 100%;
  border-radius: 8px;
`;

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: 0;
  outline: 0;
  font-size: 1.6rem;
  color: #fff;
  background: transparent;
`;

const StyledPlaceholder = styled.div`
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  z-index: 2;
  font-size: 1.8rem;
  transform-origin: left center;
  background: transparent;
  transition: transform 0.2s ease-in-out, top 0.2s ease-in-out,
    padding 0.2s ease-in-out, background 0.2s ease-in-out,
    color 0.2s ease-in-out;
  will-change: top, padding;
  color: ${({ theme }) => theme.washLight};

  span {
    color: ${({ theme }) => theme.washLight};
    margin-right: 5px;
  }

  ${StyledInput}:not(:placeholder-shown) ~ & {
    transform: scale(0.8) translateY(-50%) translateX(-10px);
    top: 0;
    padding: 0 10px;
    color: ${({ theme }) => theme.foreground};
    background: ${({ theme }) => theme.background};

    span {
      color: ${({ theme }) => theme.foreground};
    }
  }
`;

const StyledError = styled.div`
  font-size: 1.2rem;
  display: block;
  margin-top: 10px;
  pointer-events: none;
  user-select: none;
  color: #ff6a6a;
`;

const Input = forwardRef<unknown, Props>(
  (
    {
      placeholder,
      required,
      className,
      as,
      error,
      onChange,
      onBlur,
      name,
      ...props
    },
    ref
  ) => (
    <StyledWrapper className={className}>
      <StyledInnerWrapper>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore */}
        <StyledInput
          placeholder=" "
          as={as}
          ref={ref}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          {...(props as unknown)}
        />
        <StyledPlaceholder>
          {required && <span>*</span>}
          {placeholder}
        </StyledPlaceholder>
      </StyledInnerWrapper>
      {error && <StyledError>{error}</StyledError>}
    </StyledWrapper>
  )
);

interface Props extends HTMLProps<HTMLInputElement> {
  placeholder: string;
  required?: boolean;
  className?: string;
  as?: string;
  onChange?: ChangeHandler;
  onBlur?: ChangeHandler;
  name?: string;
  error?: string;
}

export default Input;
