import { createContext } from "react";

interface Context {
  isLoading: boolean;
  isLoaderExited: boolean;
}

export const MainContext = createContext<Context>({
  isLoading: null,
  isLoaderExited: null,
});
