import React, { FC, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Logo from "@components/atoms/Logo/Logo";
import Button from "@components/atoms/Button/Button";
import phoneIcon from "@iconify/icons-clarity/phone-handset-solid";
import { NAVIGATION_ITEMS } from "@config";
import { withLineHoverEffect } from "@theme/withLineHoverEffect";
import { cssEaseOutExpo } from "@theme/easings";
import { rgba } from "polished";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { Slant as Hamburger } from "hamburger-react";
import { graphql, useStaticQuery } from "gatsby";
import { transformPhoneNumber } from "@utils/transformPhoneNumber";

const StyledWrapper = styled.div<{ $scrolled: boolean }>`
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  height: 100px;
  width: 100%;
  justify-content: center;
  z-index: 999999;
  will-change: height;
  transition: height 0.5s ${cssEaseOutExpo}, background 0.5s ${cssEaseOutExpo};

  ${({ theme, $scrolled }) =>
    $scrolled &&
    css`
      height: 85px;
      background: ${rgba(theme.background, 0.7)};
    `};

  @media (max-width: 1024px) {
    background: ${({ theme }) => theme.background};
    height: 65px;
  }
`;

const StyledInnerWrapper = styled.div`
  width: 90%;
  max-width: 1780px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (min-width: 1025px) {
    .hamburger-react {
      display: none;
    }
  }
`;

const StyledLogo = styled(Logo)`
  @media (max-width: 1024px) {
    width: 100px;
  }
`;

const StyledNav = styled.nav<{ $isActive: boolean }>`
  margin-left: auto;
  margin-right: 70px;

  @media (max-width: 1150px) {
    margin-right: 30px;
  }

  @media (max-width: 1024px) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh) * 100);
    background: ${({ theme }) => theme.background};
    z-index: -1;
    padding-top: 65px;
    transition: transform 1s ${cssEaseOutExpo};
    transform: ${({ $isActive }) =>
      !$isActive ? "translateY(-100%)" : "translateY(0)"};
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const StyledItem = styled.li`
  margin-right: 40px;

  :last-of-type {
    margin-right: 0;
  }

  a {
    color: ${({ theme }) => theme.foreground};
    text-decoration: none;

    ${withLineHoverEffect()}
  }

  @media (max-width: 1150px) {
    margin-right: 20px;
  }

  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 25px;
    font-size: 2.8rem;
    font-weight: 600;
    width: 100%;
    text-align: center;

    :last-of-type {
      margin-bottom: 0;
    }

    a {
      width: 100%;
      display: block;
    }
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Navigation: FC = () => {
  const [scrollY, setScrollY] = useState<number>();
  const { scroll } = useLocomotiveScroll();
  const [isHamburgerActive, setHamburgerActive] = useState<boolean>(false);
  const { directus } =
    useStaticQuery<{ directus: { settings: { main_phone_number: string } } }>(
      query
    );

  const handleItemClick = () => {
    setHamburgerActive(false);
  };

  const initScrollHandler = () => {
    scroll?.on("scroll", (e: any) => {
      setScrollY(e.scroll.y);
    });
  };

  useEffect(() => {
    initScrollHandler();
  }, [scroll]);

  return (
    <StyledWrapper $scrolled={scrollY > 100}>
      <StyledInnerWrapper>
        <ScrollTo href="/" aria-label="Strona główna">
          <StyledLogo />
        </ScrollTo>

        <StyledNav $isActive={isHamburgerActive}>
          <StyledList>
            {NAVIGATION_ITEMS.map(({ href, name, onlyIndex }) => (
              <StyledItem key={name}>
                <ScrollTo
                  href={href}
                  onlyIndex={onlyIndex}
                  onClick={handleItemClick}
                >
                  {name}
                </ScrollTo>
              </StyledItem>
            ))}
          </StyledList>
        </StyledNav>

        <StyledButton
          icon={phoneIcon}
          forwardedAs="a"
          href={transformPhoneNumber(directus.settings.main_phone_number)}
          rel="noopener"
        >
          {directus.settings.main_phone_number}
        </StyledButton>

        <Hamburger
          toggle={setHamburgerActive}
          toggled={isHamburgerActive}
          rounded
          label={!isHamburgerActive ? "Otwórz nawigację" : "Zamknij nawigację"}
          easing={cssEaseOutExpo}
        />
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    directus {
      settings {
        main_phone_number
      }
    }
  }
`;

export default Navigation;
