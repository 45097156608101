import React, { FC } from "react";
// import LogoSvg from "@assets/svg/logo.svg";
import logoSrc from '@assets/images/logo.png'
import styled from "styled-components";

// const Logo: FC<React.SVGProps<SVGElement>> = (props) => <LogoSvg {...props} />;

const StyledLogo = styled.img`
  width: 163px;
`;

const Logo: FC<React.SVGProps<HTMLImageElement>> = (props) => <StyledLogo src={logoSrc} alt="" {...props as any} />

export default Logo;
