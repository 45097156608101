import React from "react";
import styled from "styled-components";
import { getImage } from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import { ChildImageSharp } from "@interfaces/index";
import Headline from "@components/atoms/Headline/Headline";
import Button from "@components/atoms/Button/Button";
import ScrollTo from "@components/atoms/ScrollTo/ScrollTo";
import AnimatedImage from "@components/atoms/AnimatedImage/AnimatedImage";

const StyledWrapper = styled.section`
  padding: 220px 0;
  background: ${({ theme }) => theme.backgroundSecondary};
  position: relative;

  @media (max-width: 1024px) {
    padding: 120px 0 80px;
  }

  @media (max-width: 420px) {
    padding: 80px 0 60px;
  }
`;

const StyledInnerWrapper = styled.div`
  display: flex;
  width: 90%;
  max-width: 1450px;
  margin: auto;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    position: relative;
    padding-bottom: 120px;
  }

  @media (max-width: 420px) {
    padding-bottom: 100px;
  }
`;

const StyledFigure = styled.figure`
  width: 41vw;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 130px 0 0;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 20px 0 0 0;
  }
`;

const StyledImage = styled(AnimatedImage)`
  width: 100%;
  height: 31vw;
  max-height: 600px;
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    height: auto;
  }
`;

const StyledFigCaption = styled.figcaption`
  text-align: center;
  line-height: 1.5;
`;

const StyledRightWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledHeadline = styled(Headline)`
  margin-bottom: 20px;

  @media (max-width: 1024px) {
    margin-bottom: 60px;
  }
`;

const StyledParagraph = styled.p`
  line-height: 2;
  margin: 0 0 30px;

  :last-of-type {
    margin: 0 0 40px;
  }

  @media (max-width: 1024px) {
    max-width: 600px;
  }
`;

const StyledButton = styled(Button)`
  @media (max-width: 1024px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    transform-origin: center center;

    :active {
      transform: scale(0.9) translateX(-50%);
    }
  }
`;

const AboutUs = () => {
  const { file } = useStaticQuery<{ file: ChildImageSharp }>(query);

  return (
    <StyledWrapper id="about_us" data-scroll-section>
      <StyledInnerWrapper>
        <StyledFigure data-scroll data-scroll-speed="2">
          <StyledImage
            image={getImage(file.childImageSharp)}
            alt="Zarząd firmy Marys sp. z o.o. - Laura Krakowska-Dudek, Grzegorz
            Dudek"
            background="#1E1E1D"
          />
          <StyledFigCaption>
            Zarząd firmy Marys sp. z o.o. - Laura Krakowska-Dudek, Grzegorz
            Dudek
          </StyledFigCaption>
        </StyledFigure>
        <StyledRightWrapper data-scroll data-scroll-speed="1">
          <StyledHeadline>O nas</StyledHeadline>

          <StyledParagraph>
            Marys sp. z o.o. jest rodzinną firmą, działającą na rynku
            nieruchomości od 2010r. Od wielu lat jesteśmy związani z branżą
            budowlaną oraz wnętrzarską, w swoim portfelu posiadamy również
            nieruchomości na wynajem.
          </StyledParagraph>
          <StyledParagraph>
            Dzięki naszemu doświadczeniu z powodzeniem tworzymy kolejne
            indywidualne projekty deweloperskie, mieszkania pod klucz dla rodzin
            oraz produkty inwestycyjne przygotowane do wynajmu krótko lub
            długoterminowego. Swoje inwestycje prowadzimy głównie na terenie
            Polski południowej.
          </StyledParagraph>
          <StyledParagraph>
            Nad powodzeniem realizowanych projektów czuwa zespół złożony z
            architektów, prawników, notariuszy i wykonawców, który jest z nami
            przy każdym przedsięwzięciu.
          </StyledParagraph>

          <StyledButton forwardedAs={ScrollTo} href="#contact">
            Skontaktuj się
          </StyledButton>
        </StyledRightWrapper>
      </StyledInnerWrapper>
    </StyledWrapper>
  );
};

const query = graphql`
  {
    file(name: { eq: "about_us" }) {
      childImageSharp {
        gatsbyImageData(quality: 80)
      }
    }
  }
`;

export default AboutUs;
